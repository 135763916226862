import React, { useEffect, useState } from 'react';
import { Box, Text, Button, VStack, Icon, Center } from '@chakra-ui/react';
import { FaRegSmileBeam } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

const SurveyComplete = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [closingMessage, setClosingMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchClosingMessage = async () => {
      try {
        const response = await axios.post(`/public_surveys/${endpoint}/complete`, {
          uuid: JSON.parse(localStorage.getItem('survey_session'))?.uuid,
        });
        setClosingMessage(
          response.data.message || t('public_survey.complete.defaultMessage')
        );
      } catch (error) {
        console.error('Error fetching closing message:', error);
        setClosingMessage(t('public_survey.complete.defaultErrorMessage'));
      }
    };

    fetchClosingMessage();
  }, [endpoint, t]);

  const handleReturnHome = () => {
    localStorage.removeItem('survey_session');
    navigate('/');
  };

  return (
    <Box p={6} mx="auto" maxW="lg" textAlign="center">
      {/* Hero Section */}
      <VStack spacing={6}>
        <Center>
          <Icon as={FaRegSmileBeam} boxSize={12} color="yellow.400" />
        </Center>
        <Text fontSize="3xl" fontWeight="bold" color="teal.600">
          {t('public_survey.complete.thankYou')}
        </Text>
        <Text fontSize="lg" color="gray.600">
          {t('public_survey.complete.appreciation')}
        </Text>
      </VStack>

      {/* Closing Message */}
      <Box
        mt={8}
        p={6}
        bg="white"
        border="1px"
        borderColor="gray.200"
        borderRadius="md"
        shadow="md"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(closingMessage) }}
      />

      {/* Finish Button */}
      <Button
        mt={8}
        size="lg"
        colorScheme="teal"
        onClick={handleReturnHome}
        variant="outline"
        _hover={{ bg: 'teal.600', color: "white" }}
      >
        {t('public_survey.complete.finishSurvey')}
      </Button>
    </Box>
  );
};

export default SurveyComplete;