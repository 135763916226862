import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import UserForm from './UserForm';
import axios from '../../axiosConfig';

const UserEdit = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data } = await axios.get(`/users/${id}`);
        setUser(data); // Populate form with fetched user data
      } catch (error) {
        console.error('Failed to load user data:', error);
      }
    };
    fetchUser();
  }, [id]);

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Edit User</Heading>
      {user ? <UserForm initialValues={user} /> : <p>Loading...</p>}
    </Box>
  );
};

export default UserEdit;