import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SurveyEntry from './SurveyEntry';
import SurveyAcknowledgment from './SurveyAcknowledgment';
import SurveyQuestions from './SurveyQuestions';
import SurveyComplete from './SurveyComplete';
import PublicSurveyGuard from './PublicSurveyGuard';
import SurveyLayout from './SurveyLayout';

const PublicSurveyRoutes = () => {
  return (
    <SurveyLayout>
      <Routes>
        <Route path="" element={<SurveyEntry />} />
        <Route
          path="acknowledgment"
          element={
            <PublicSurveyGuard>
              <SurveyAcknowledgment />
            </PublicSurveyGuard>
          }
        />
        <Route
          path="questions"
          element={
            <PublicSurveyGuard>
              <SurveyQuestions />
            </PublicSurveyGuard>
          }
        />
        <Route
          path="complete"
          element={
            <PublicSurveyGuard>
              <SurveyComplete />
            </PublicSurveyGuard>
          }
        />
      </Routes>
    </SurveyLayout>
  );
};

export default PublicSurveyRoutes;