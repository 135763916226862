import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, Box, ColorModeScript } from '@chakra-ui/react';
import CookieConsentModal from './components/layout/CookieConsentDrawer';
import { UserProvider } from './contexts/UserContext';
import { SurveyProvider } from './contexts/SurveyContext';
import customTheme from './theme';

import LoginPage from './components/account/LoginPage';
import Website from './site/components/Website';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';
import Profile from './components/account/Profile';
import Settings from './components/account/Settings';
import ChangePassword from './components/account/ChangePassword';
import Home from './components/Home';
import OrganizationRoutes from './components/organization/OrganizationRoutes';
import SurveyRoutes from './components/survey/SurveyRoutes';
import UserRoutes from './components/users/UserRoutes';
import MessagesList from './components/MessagesList';
import ProtectedRoute from './components/layout/ProtectedRoute';
import ProtectedLayout from './components/layout/ProtectedLayout';
import PublicSurveyRoutes from './components/public_surveys/PublicSurveyRoutes';

function App() {
  return (
    <UserProvider>
      <ChakraProvider theme={customTheme}>
        <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
        <Router>
          <CookieConsentModal />
          <Routes>
            {/* Public website route */}
            <Route path="/" element={<Website />} />

            {/* Public surveys route */}
            <Route path="/survey/:endpoint/*" element={<PublicSurveyRoutes/>} />

            {/* Login page */}
            <Route path="/login" element={<LoginPage />} />

            {/* Protected routes */}
            <Route path="/app" element={
              <ProtectedRoute>
                <ProtectedLayout />
              </ProtectedRoute>
            }>
              <Route path="home" element={<Home />} />
              <Route path="organizations/*" element={<OrganizationRoutes />} />
              <Route path="users/*" element={<UserRoutes />} />
              <Route path="messages" element={<MessagesList />} />
              <Route path="surveys/*" element={
                <SurveyProvider>
                  <SurveyRoutes />
                </SurveyProvider>
              } />                
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
              <Route path="password" element={<ChangePassword />} />
            </Route>

            {/* Catch-all route for 404 */}
            <Route path="*" element={<NotFound />} />
            <Route path="/server-error" element={<ServerError />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </UserProvider>
  );
}

export default App;