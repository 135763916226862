import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';
import axios from '../../axiosConfig';
import SurveyForm from './SurveyForm';

const SurveyCreate = () => {
  const navigate = useNavigate();

  const handleCreate = async (data) => {
    try {
      const response = await axios.post('/surveys', data);
      navigate(`/app/surveys/${response.data.id}/pages`);
    } catch (error) {
      console.error('Error creating survey:', error);
    }
  };

  return (
    <Box>
      <Heading as="h2" size="lg" mb={6}>
        Add Survey
      </Heading>
      <SurveyForm onSubmit={handleCreate} />
    </Box>
  );
};

export default SurveyCreate;