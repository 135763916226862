import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  VStack,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';

const SurveyForm = ({ onSubmit, initialData = {} }) => {
  const [surveyName, setSurveyName] = useState(initialData.name || '');
  const [language, setLanguage] = useState(initialData.language || '');
  const [passcode, setPasscode] = useState(initialData.passcode || '');
  const [endMessage, setEndMessage] = useState(initialData.end_message || '');
  const [groups, setGroups] = useState(
    initialData.survey_groups?.map((group) => ({
      ...group,
      localId: group.id || Date.now() + Math.random(), // Unique ID for each group
    })) || [{ name: '', localId: Date.now() + Math.random() }]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    // Prepare the data in the format expected by the API
    onSubmit({
      name: surveyName,
      language,
      passcode,
      end_message: endMessage,
      survey_groups_attributes: groups.map(({ name, id, _destroy }) => ({
        name,
        id,
        _destroy,
      })),
    });
  };

  const handleGroupChange = (localId, value) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.localId === localId ? { ...group, name: value } : group
      )
    );
  };

  const handleAddGroup = () => {
    setGroups((prevGroups) => [
      ...prevGroups,
      { name: '', localId: Date.now() + Math.random() },
    ]);
  };

  const handleRemoveGroup = (localId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.localId === localId
          ? { ...group, _destroy: group.id ? true : undefined }
          : group
      ).filter((group) => group._destroy !== undefined || !group._destroy)
    );
  };

  return (
    <Box mx="auto" p={6}>
      <VStack spacing={4} as="form" onSubmit={handleSubmit}>
        <FormControl isRequired>
          <FormLabel>Name of the Survey</FormLabel>
          <Input
            type="text"
            value={surveyName}
            onChange={(e) => setSurveyName(e.target.value)}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Language</FormLabel>
          <Select
            placeholder="Select language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="english">English</option>
            <option value="swedish">Swedish</option>
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Passcode (optional)</FormLabel>
          <Input
            type="text"
            value={passcode}
            onChange={(e) => setPasscode(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <FormLabel>End Message</FormLabel>
          <Textarea
            value={endMessage}
            onChange={(e) => setEndMessage(e.target.value)}
            placeholder="Enter a message to display after survey completion"
          />
        </FormControl>

        <FormControl>
          <FormLabel>Survey Groups</FormLabel>
          {groups
            .filter((group) => !group._destroy) // Only show groups not marked for deletion
            .map((group) => (
              <HStack key={group.localId} spacing={3}>
                <Input
                  placeholder="Enter group name"
                  value={group.name}
                  onChange={(e) => handleGroupChange(group.localId, e.target.value)}
                />
                <IconButton
                  icon={<FaTrash />}
                  colorScheme="red"
                  aria-label="Remove group"
                  onClick={() => handleRemoveGroup(group.localId)}
                />
              </HStack>
            ))}
          <Button mt={2} onClick={handleAddGroup} colorScheme="blue">
            Add Group
          </Button>
        </FormControl>

        <Flex justifyContent="flex-end" width="100%">
          <Button colorScheme="green" type="submit">
            {initialData.name ? 'Update Survey' : 'Create Survey'}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default SurveyForm;