import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  VStack,
  Heading,
  Text,
} from '@chakra-ui/react';

const QuestionForm = ({ initialData = {}, onSubmit, onCancel, surveyDetails, groups }) => {
  const [questionText, setQuestionText] = useState(initialData.question_text || '');
  const [questionType, setQuestionType] = useState(initialData.question_type || '');
  const [position, setPosition] = useState(initialData.position || 1);
  const [customizations, setCustomizations] = useState(
    groups.map((group) => {
      const existingCustomization = initialData.customizations?.find(
        (c) => c.survey_group_id === group.id
      );
      return {
        id: existingCustomization?.id || null, // Include the `id` for existing customizations
        surveyGroupId: group.id,
        surveyGroupName: group.name,
        customQuestionText: existingCustomization?.custom_question_text || '',
        visible: existingCustomization?.visible !== false, // Default to true if not explicitly false
      };
    })
  );

  useEffect(() => {
    setCustomizations((prevCustomizations) =>
      groups.map((group) => {
        const existing = prevCustomizations.find((c) => c.surveyGroupId === group.id);
        return (
          existing || {
            id: null, // Default to `null` for new customizations
            surveyGroupId: group.id,
            surveyGroupName: group.name,
            customQuestionText: '',
            visible: true,
          }
        );
      })
    );
  }, [groups]);

  const handleCustomizationChange = (surveyGroupId, field, value) => {
    setCustomizations((prev) =>
      prev.map((customization) =>
        customization.surveyGroupId === surveyGroupId ? { ...customization, [field]: value } : customization
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      question_text: questionText,
      question_type: questionType,
      position,
      question_customizations_attributes: customizations.map(
        ({ id, surveyGroupId, customQuestionText, visible }) => ({
          id, // Include `id` to update existing records
          survey_group_id: surveyGroupId,
          custom_question_text: customQuestionText,
          visible,
        })
      ),
    });
  };

  return (
    <Box mt={4}>
      <Heading as="h1" size="lg" mb={4}>
        {surveyDetails.surveyName}
      </Heading>
      <Text fontSize="lg" mb={6} color="gray.600">
        {surveyDetails.pageTitle}
      </Text>

      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Question Text</FormLabel>
            <Input
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
              placeholder="Enter the question text"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Question Type</FormLabel>
            <Select
              placeholder="Select question type"
              value={questionType}
              onChange={(e) => setQuestionType(e.target.value)}
            >
              <option value="number">Number</option>
              <option value="text">Text</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Position</FormLabel>
            <NumberInput min={1} value={position} onChange={(value) => setPosition(value)}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          {customizations.map(({ id, surveyGroupId, surveyGroupName, customQuestionText, visible }) => (
            <Box key={surveyGroupId} p={4} borderWidth={1} borderRadius="md" width="100%">
              <Text fontWeight="bold" mb={2}>
                Group: {surveyGroupName}
              </Text>
              <FormControl>
                <FormLabel>Custom Text</FormLabel>
                <Input
                  value={customQuestionText}
                  onChange={(e) =>
                    handleCustomizationChange(surveyGroupId, 'customQuestionText', e.target.value)
                  }
                  placeholder={`Custom text for ${surveyGroupName}`}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Visible?</FormLabel>
                <Select
                  value={visible ? 'yes' : 'no'}
                  onChange={(e) =>
                    handleCustomizationChange(surveyGroupId, 'visible', e.target.value === 'yes')
                  }
                >
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Select>
              </FormControl>
            </Box>
          ))}

          <Button type="submit" variant="outline" colorScheme="green">
            {initialData.id ? 'Update Question' : 'Add Question'}
          </Button>
          <Button colorScheme="gray" variant="outline" onClick={onCancel}>
            Cancel
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default QuestionForm;