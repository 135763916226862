import React from 'react';
import { Box, Heading } from '@chakra-ui/react';

const Home = () => {
  return (
    <Box>
      <Heading>Home</Heading>
      <p>Welcome to the Home section of the dashboard.</p>
    </Box>
  );
};

export default Home;