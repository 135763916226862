import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const Dashboard = () => {
  return (
    <Flex>
      {/* Sidebar */}
      <Box display={{ base: 'none', md: 'block' }}>
        <Sidebar />
      </Box>

      {/* Main Content */}
      <Box ml={{ base: 0, md: '250px' }} width="100%">
        <Navbar />

        {/* Content Area */}
        <Box p={4} className="content-box">
          <Outlet /> {/* This will render the nested routes */}
        </Box>
      </Box>
    </Flex>
  );
};

export default Dashboard;