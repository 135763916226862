import React from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FaTrash, FaPencilAlt, FaRegFileAlt } from 'react-icons/fa';

const SurveyList = ({ surveys, handleDelete }) => {
  const navigate = useNavigate();

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Surveys</Heading>
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Spacer />
        <Button
          as={Link}
          to="/app/surveys/create"
          mt={4}
          colorScheme="green"
          variant="outline"
          size="sm"
        >
          Add Survey
        </Button>
      </Flex>

      {surveys.length > 0 ? (
        <TableContainer mt={6}>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Pages</Th>
                <Th>Questions</Th>
                <Th>Language</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {surveys.map((survey) => (
                <Tr key={survey.id}>
                  <Td>{survey.name}</Td>
                  <Td>{survey.pages_count}</Td>
                  <Td>{survey.questions_count}</Td>
                  <Td>{survey.language}</Td>
                  <Td>
                    <IconButton
                      icon={<FaRegFileAlt />}
                      aria-label="Manage Pages"
                      title="Manage Pages"
                      variant="outline"
                      colorScheme="cyan"
                      size="sm"
                      onClick={() => navigate(`/app/surveys/${survey.id}/pages`)}
                      mr={2}
                      />
                    <IconButton
                      as={Link}
                      to={`/app/surveys/edit/${survey.id}`}
                      icon={<FaPencilAlt />}
                      aria-label="Edit Survey"
                      variant="outline"
                      title="Edit Survey"
                      size="sm"
                      colorScheme="yellow"
                      mr={2}
                      />
                    <IconButton
                      icon={<FaTrash />}
                      aria-label="Delete Survey"
                      title="Delete Survey"
                      variant="outline"
                      size="sm"
                      colorScheme="red"
                      onClick={() => handleDelete(survey.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text mt={6} textAlign="center">
          No surveys found.
        </Text>
      )}
    </Box>
  );
};

export default SurveyList;