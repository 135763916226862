import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Text,
  VStack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SurveyEntry = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [passcode, setPasscode] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
    if (surveySession.organizationName && surveySession.endpoint === endpoint) {
      setOrganizationName(surveySession.organizationName);
    } else {
      const fetchSurveyDetails = async () => {
        try {
          const response = await axios.get(`/public_surveys/${endpoint}`);
          const { organization_name } = response.data;
          setOrganizationName(organization_name || '');
          localStorage.setItem(
            'survey_session',
            JSON.stringify({ ...surveySession, organizationName: organization_name, endpoint })
          );
        } catch (error) {
          console.error('Error fetching survey details:', error);
        }
      };

      fetchSurveyDetails();
    }

    if (surveySession?.valid && surveySession.endpoint === endpoint) {
      navigate('acknowledgment');
    }
  }, [endpoint, navigate]);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`/public_surveys/${endpoint}/validate_passcode`, { passcode });
      if (response.status === 200) {
        const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
        localStorage.setItem(
          'survey_session',
          JSON.stringify({ ...surveySession, endpoint, valid: true })
        );
        navigate('acknowledgment');
      }
    } catch (error) {
      setError(t('public_survey.errors.invalidPasscode')); // Translated error message
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <Box p={6} mx="auto" maxW="lg">
      {/* Hero Section */}
      <VStack spacing={6}>
        <Flex justify="center" align="center" direction="column">
          <Image src="/images/logo-md.png" alt="HKE Learning Logo" mb={4} />
          <Heading size="lg" color={useColorModeValue('teal.600', 'teal.300')} textAlign="center">
            {organizationName || t('public_survey.entry.defaultTitle')} {/* Translated title */}
          </Heading>
        </Flex>

        <Card border="1px" borderColor="gray.200" shadow="md" p={4}>
          <CardHeader>
            <Heading size="md" textAlign="center" color="gray.700">
              {t('public_survey.entry.enterPasscode')} {/* Translated subtitle */}
            </Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel>{t('public_survey.entry.passcodeLabel')}</FormLabel> {/* Translated label */}
                <Input
                  boxShadow="md"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                  onKeyDown={handleKeyDown} // Listen for Enter key press
                />
              </FormControl>
              {error && (
                <Text color="red.500" fontSize="sm">
                  {error}
                </Text>
              )}
              <Button
                colorScheme="teal"
                width="full"
                variant="outline"
                onClick={handleSubmit}
                _hover={{ bg: 'teal.600', color: "white" }}
                _focus={{ boxShadow: 'outline' }}
              >
                {t('public_survey.entry.startSurvey')} {/* Translated button text */}
              </Button>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Box>
  );
};

export default SurveyEntry;