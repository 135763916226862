import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';

const QuestionsContext = createContext();

export const useQuestions = () => useContext(QuestionsContext);

export const QuestionsProvider = ({ surveyId, surveyPageId, children }) => {
  const [questions, setQuestions] = useState([]);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(`/surveys/${surveyId}/survey_pages/${surveyPageId}/questions`);
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const createQuestion = async (questionData) => {
    try {
      const response = await axios.post(
        `/surveys/${surveyId}/survey_pages/${surveyPageId}/questions`,
        questionData
      );
      setQuestions([...questions, response.data]);
    } catch (error) {
      console.error("Error creating question:", error);
    }
  };

  const updateQuestion = async (questionId, questionData) => {
    try {
      const response = await axios.put(
        `/surveys/${surveyId}/survey_pages/${surveyPageId}/questions/${questionId}`,
        questionData
      );
      setQuestions(questions.map((q) => (q.id === questionId ? response.data : q)));
    } catch (error) {
      console.error("Error updating question:", error);
    }
  };

  const deleteQuestion = async (questionId) => {
    const confirmation = window.confirm("Are you sure you want to delete this question?");
    if (!confirmation) return;

    try {
      await axios.delete(`/surveys/${surveyId}/survey_pages/${surveyPageId}/questions/${questionId}`);
      setQuestions(questions.filter((q) => q.id !== questionId));
    } catch (error) {
      console.error("Error deleting question:", error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [surveyId, surveyPageId]);

  return (
    <QuestionsContext.Provider
      value={{
        questions,
        fetchQuestions,
        createQuestion,
        updateQuestion,
        deleteQuestion,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};