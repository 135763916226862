import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputRightElement, IconButton, Select, VStack, useToast } from '@chakra-ui/react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from '../../axiosConfig';

const UserForm = ({ initialValues = { first_name: '', last_name: '', email: '', role: 'user', password: '', password_confirmation: '' } }) => {
  // Set initial values only once when the form initializes
  const [formValues, setFormValues] = useState({ ...initialValues });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  // Handle input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrors({});  // Reset errors before new submission

    // Check if passwords match
    if (formValues.password !== formValues.password_confirmation) {
      setErrors({ password_confirmation: ["Passwords do not match"] });
      setIsSubmitting(false);
      return;
    }

    try {
      if (initialValues.id) {
        // Update existing user
        await axios.put(`/users/${initialValues.id}`, { user: formValues });
        toast({
          title: 'Success',
          description: 'User updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // Create new user
        await axios.post('/users', { user: formValues });
        toast({
          title: 'Success',
          description: 'User created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
      navigate('/app/users')
    } catch (error) {
      console.log(error);
      // if (error.response && error.response.data) {
      if (error && error.data) {
        // const errorMessages = error.response.data.errors || [];
        const errorMessages = error.data.errors || [];
        setErrors({ general: errorMessages });
        toast({
          title: 'Error',
          description: `There was an issue with your submission. ${errorMessages}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: 'Error',
          description: 'An unexpected error occurred.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <FormControl isRequired>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="first_name"
            value={formValues.first_name}
            onChange={handleInputChange}
            isInvalid={errors.first_name}
          />
          {errors.first_name && <p style={{ color: 'red' }}>{errors.first_name[0]}</p>}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="last_name"
            value={formValues.last_name}
            onChange={handleInputChange}
            isInvalid={errors.last_name}
          />
          {errors.last_name && <p style={{ color: 'red' }}>{errors.last_name[0]}</p>}
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}isInvalid={errors.email}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email[0]}</p>}
        </FormControl>
        {/* Password: Only required for new users */}
        <FormControl isRequired={!initialValues.id}>
          <FormLabel>Password {initialValues.id ? '(Leave blank to keep unchanged)' : ''}</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formValues.password}
              onChange={handleInputChange}
              isInvalid={errors.password}
            />
            <InputRightElement>
              <IconButton
                icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                onClick={() => setShowPassword(!showPassword)}
                aria-label={showPassword ? 'Hide Password' : 'Show Password'}
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
          {errors.password && <p style={{ color: 'red' }}>{errors.password[0]}</p>}
        </FormControl>

        {/* Confirm Password */}
        <FormControl isRequired={!initialValues.id}>
          <FormLabel>Password Confirmation</FormLabel>
          <InputGroup>
            <Input
              type={showConfirmPassword ? 'text' : 'password'}
              name="password_confirmation"
              value={formValues.password_confirmation}
              onChange={handleInputChange}
              isInvalid={errors.password_confirmation}
            />
            <InputRightElement>
              <IconButton
                icon={showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                aria-label={showConfirmPassword ? 'Hide Confirm Password' : 'Show Confirm Password'}
                variant="ghost"
              />
            </InputRightElement>
          </InputGroup>
          {errors.password_confirmation && <p style={{ color: 'red' }}>{errors.password_confirmation[0]}</p>}
        </FormControl>
        <FormControl>
          <FormLabel>Role</FormLabel>
          <Select
            name="role"
            value={formValues.role}
            onChange={handleInputChange}
          >
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </Select>
        </FormControl>
        <Flex justifyContent="flex-end" width="100%">
          <Button type="submit" colorScheme="green" isLoading={isSubmitting}>
            {initialValues.id ? 'Update User' : 'Create User'}
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default UserForm;