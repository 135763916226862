import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SurveyCreate from './SurveyCreate';
import SurveyEdit from './SurveyEdit';
import SurveyList from './SurveyList';
import SurveyPageList from './SurveyPageList';
import SurveyPageCreate from './SurveyPageCreate';
import SurveyPageEdit from './SurveyPageEdit';
import QuestionRoutes from '../questions/QuestionRoutes';
import { useSurvey } from '../../contexts/SurveyContext';

const SurveyRoutes = () => {
  const { surveys } = useSurvey();

  return (
    <Routes>
      <Route path="" element={<SurveyList surveys={surveys} />} />
      <Route path="/create" element={<SurveyCreate />} />
      <Route path="/edit/:id" element={<SurveyEdit />} />
      <Route path=":surveyId/pages" element={<SurveyPageList />} />
      <Route path=":surveyId/pages/create" element={<SurveyPageCreate />} />
      <Route path=":surveyId/pages/edit/:pageId" element={<SurveyPageEdit />} />
      <Route path=":surveyId/pages/:pageId/questions/*" element={<QuestionRoutes />} />
    </Routes>
  );
};

export default SurveyRoutes;