import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ children }) => {
  const authToken = Cookies.get('hkeAuthToken');
  
  if (!authToken) {
    return <Navigate to="/login" />;
  }

  // Check token expiration based on its creation time, as an alternative to jwtDecode
  const tokenExpiryTime = 3600 * 1000; // Adjust this time as per your JWT expiration duration
  const tokenCreationTime = Cookies.get('tokenCreationTime'); // Assume we set this at login
  
  if (Date.now() - tokenCreationTime > tokenExpiryTime) {
    Cookies.remove('hkeAuthToken');
    Cookies.remove('tokenCreationTime');
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;