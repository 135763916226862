import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Heading, useToast } from '@chakra-ui/react';
import { useUser } from '../../contexts/UserContext';

const Profile = () => {
  const { userData, updateUser } = useUser();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const toast = useToast();

  useEffect(() => {
    if (userData) {
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setEmail(userData.email);
    }
  }, [userData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser({ first_name: firstName, last_name: lastName, email });
    toast({
      title: "Profile updated.",
      description: "Your profile information has been updated successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box maxW="lg" mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Edit Profile</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              disabled={true}
              // onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>

          <Button type="submit" colorScheme="green" size="lg" width="full">
            Save Changes
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Profile;