import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  TableContainer,
  Spacer,
} from '@chakra-ui/react';
import { FaPencilAlt, FaTrash } from 'react-icons/fa';
import { useQuestions } from '../../contexts/QuestionsContext';
import { Link, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const QuestionsList = () => {
  const { surveyId, pageId } = useParams();
  const { questions, deleteQuestion } = useQuestions();

  const [surveyPage, setSurveyPage] = useState(null);

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`);
        setSurveyPage(response.data);
      } catch (error) {
        console.error("Error fetching survey pages:", error);
      }
    };

    fetchSurvey();
  }, [pageId]);

  return (
    <Box mt={6}>
      <Heading as="h2" size="lg" mb={4}>
        {surveyPage ? surveyPage.name : 'Survey Page'}
      </Heading>
      <Text fontSize="lg" color="gray.500">Questions</Text>
      <Flex mb={4}>
        <Spacer />
        <Button
          colorScheme="green"
          as={Link}
          size="sm"
          variant="outline"
          to={`/app/surveys/${surveyId}/pages/${pageId}/questions/create`}
        >
          Add Question
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Question Text</Th>
              <Th>Type</Th>
              <Th>Position</Th>
              <Th>Customizations</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {questions
              .sort((a, b) => a.position - b.position)
              .map((question) => (
                <Tr key={question.id}>
                  <Td title={question.question_text} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>
                    {question.question_text}
                  </Td>
                  <Td>{question.question_type}</Td>
                  <Td>{question.position}</Td>
                  <Td>
                    {question.customizations?.length
                      ? `${question.customizations.length} group customizations`
                      : 'None'}
                  </Td>
                  <Td>
                    <Link
                      to={`/app/surveys/${surveyId}/pages/${pageId}/questions/edit/${question.id}`}
                    >
                      <IconButton
                        icon={<FaPencilAlt />}
                        aria-label="Edit Question"
                        title="Edit Question"
                        variant="outline"
                        colorScheme="yellow"
                        size="sm"
                        mr={2}
                      />
                    </Link>
                    <IconButton
                      icon={<FaTrash />}
                      aria-label="Delete Question"
                      title="Delete Question"
                      variant="outline"
                      onClick={() => deleteQuestion(question.id)}
                      size="sm"
                      colorScheme="red"
                    />
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default QuestionsList;