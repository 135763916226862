import React from 'react';
import { Outlet } from 'react-router-dom';
import Dashboard from './Dashboard';

const ProtectedLayout = () => (
  <Dashboard>
    <Outlet />
  </Dashboard>
);

export default ProtectedLayout;