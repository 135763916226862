import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  // Flex,
  Heading,
  Text,
  // Link,
  // Button,
  Icon,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  // Spacer
} from '@chakra-ui/react';
import { FaEnvelope, FaEnvelopeOpen, FaEye } from 'react-icons/fa';
import axios from '../axiosConfig';
import { useColorModeValue } from '@chakra-ui/react';

const MessagesList = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Helper function to map message data
  const mapMessageReadStatus = (message) => ({
    ...message,
    read: message.status === 'read', // Set read based on the status field
  });

  // Fetch messages from the backend
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('/messages');
        const mappedMessages = response.data.map(mapMessageReadStatus);
        setMessages(mappedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const openMessage = (message) => {
    setSelectedMessage(message);
    onOpen();
    if (!message.read) {
      markAsRead(message.id);
    }
  };

  const markAsRead = async (messageId) => {
    try {
      await axios.patch(`/messages/${messageId}`, { status: 'read' }); // Update status to 'read'
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId ? { ...msg, read: true } : msg
        )
      );
    } catch (error) {
      console.error('Error marking message as read:', error);
    }
  };

  const bgColor = useColorModeValue('gray.100', 'gray.700');

  return (
    <>
      <Heading as="h1" size="lg" mb={6}>Messages</Heading>
      {/* <Flex mt={4} mb={6}>
        <Spacer />
        <Button colorScheme="green" variant="outline" as={Link} size="sm" to={`/app/messages/archived`}>
          Show Archived
        </Button>
      </Flex> */}

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Read/Unread</Th>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Message</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {messages.map((message) => (
              <Tr key={message.id} bg={bgColor}>
                <Td>
                  <Icon
                    as={message.read ? FaEnvelopeOpen : FaEnvelope}
                    boxSize={6}
                    color={message.read ? 'gray.500' : 'blue.500'}
                  />
                </Td>
                <Td>
                  <Text fontWeight={message.read ? 'normal' : 'bold'}>
                    {message.name}
                  </Text>
                </Td>
                <Td>{message.email}</Td>
                <Td maxW="200px">
                  <Text isTruncated>{message.content}</Text>
                </Td>
                <Td>
                  <IconButton
                    icon={<FaEye />}
                    aria-label="View Message"
                    variant="outline"
                    title="View Message"
                    colorScheme="cyan"
                    mr={2}
                    size="sm"
                    onClick={() => openMessage(message)}
                    />
                  {/* <Link to={openMessage(message)}>
                  </Link> */}
                  {/* <Button
                    colorScheme="cyan"
                    size="sm"
                    variant="outline"
                    onClick={() => openMessage(message)}
                  >
                    View
                  </Button> */}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Message Modal */}
      {selectedMessage && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {selectedMessage.name} ({selectedMessage.email})
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                <Text fontSize="md">{selectedMessage.content}</Text>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default MessagesList;