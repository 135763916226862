import React, { useEffect, useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  IconButton,
  Flex,
  Heading,
  Text,
  Spacer
} from '@chakra-ui/react';
import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import axios from '../../axiosConfig'; 

const UserList = () => {
  const [users, setUsers] = useState([]);
  const { userData } = useUser();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users');  // Fetch users using axios
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleDelete = async (id) => {
    if (id === userData.id) {
      alert('You cannot delete yourself!');
      return;
    }
    try {
      await axios.delete(`/users/${id}`);  // Delete user using axios
      setUsers(users.filter((user) => user.id !== id));
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  // Return early if userData is not yet available
  if (!userData) {
    return <Text>Loading...</Text>;
  }  

  return (
    <Flex direction="column" p={8}>
      <Heading as="h2" size="lg" mb={6}>
        Users
      </Heading>
      <Flex minWidth='max-content' alignItems='center' gap='2'>
        <Spacer />
        <Button
          as={Link}
          to="/app/users/create"
          mt={4}
          colorScheme="green"
          variant="outline"
          size="sm"
        >
          Add User
        </Button>
      </Flex>
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.length > 0 ? (
              users.map((user) => (
                <Tr key={user.id}>
                  <Td>{`${user.first_name} ${user.last_name}`}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.role}</Td>
                  <Td>
                    {/* Only Master and Admin can edit users */}
                    {(userData.role === 'master' || userData.role === 'admin') && (
                      <>
                        <IconButton
                          as={Link}
                          to={`/app/users/edit/${user.id}`}
                          icon={<FaPencilAlt />}
                          aria-label="Edit User"
                          title="Edit User"
                          variant="outline"
                          size="sm"
                          mr={2}
                          colorScheme="yellow"
                          />
                        {/* Only allow deletion if the user isn't deleting themselves */}
                        <IconButton
                          icon={<FaTrash />}
                          aria-label="Delete User"
                          title="Delete User"
                          variant="outline"
                          colorScheme="red"
                          size="sm"
                          onClick={() => handleDelete(user.id)}
                          isDisabled={user.id === userData.id} // Prevent user from deleting themselves
                        />
                      </>
                    )}
                  </Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Text textAlign="center">No users found.</Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default UserList;