import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from '../axiosConfig';

const SurveyContext = createContext();

export const useSurvey = () => useContext(SurveyContext);

export const SurveyProvider = ({ children }) => {
  const [surveys, setSurveys] = useState([]);

  // Function to fetch surveys
  const fetchSurveys = async () => {
    try {
      const response = await axios.get('/surveys');
      setSurveys(response.data);
    } catch (error) {
      console.error("Error fetching surveys:", error);
    }
  };

  useEffect(() => {
    fetchSurveys(); // Initial fetch when the component mounts
  }, []);

  return (
    <SurveyContext.Provider value={{ surveys, fetchSurveys }}>
      {children}
    </SurveyContext.Provider>
  );
};