import { Box, Heading, Text, Button, Image, useColorMode } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function ServerError() {
  const navigate = useNavigate();
  const authToken = Cookies.get('hkeAuthToken');

  const { colorMode } = useColorMode();
  const logo = colorMode === 'dark' ? 'logo-white-md.png' : 'logo-md.png';

  return (
    <Box textAlign="center" py={10} px={6}>
      <Image src={`/images/${logo}`} alt="HKE Learning" mx="auto" />
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        mt={8}
        bgGradient="linear(to-r, orange.400, orange.600)"
        backgroundClip="text">
        50x
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Oops! Something went wrong.
      </Text>
      <Text color={'gray.500'} mb={6}>
        We're experiencing some technical issues. Please try again later.
      </Text>

      <Button
        colorScheme="orange"
        bgGradient="linear(to-r, orange.400, orange.500, orange.600)"
        color="white"
        variant="solid"
        onClick={() => authToken ? navigate('/app/home') : navigate('/')}
      >
        Go to Home
      </Button>
    </Box>
  );
}