import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Slider,
  SliderMark,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  RadioGroup,
  Radio,
  Text,
  Progress,
  Stack,
  Card,
  CardBody,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SurveyQuestions = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [pageInfo, setPageInfo] = useState({ position: 0, title: '' });
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    if (!surveySession?.group || !surveySession?.role) {
      navigate('../acknowledgment');
    }
  }, [navigate]);

  const fetchQuestions = async (page = null) => {
    try {
      const surveySession = JSON.parse(localStorage.getItem('survey_session'));
      const response = await axios.get(`/public_surveys/${endpoint}/questions`, {
        params: {
          uuid: surveySession.uuid,
          group_id: surveySession.group,
          page,
        },
      });

      if (response.data.completed) {
        navigate('../complete');
        return;
      }

      const { page: newPageInfo, questions: newQuestions } = response.data;
      setPageInfo(newPageInfo);
      setQuestions(newQuestions);

      // Initialize answers as null for all questions
      const initialAnswers = {};
      newQuestions.forEach((q) => {
        initialAnswers[q.id] = 4;
      });
      setAnswers(initialAnswers);

      // Calculate progress
      const totalPages = response.data.total_pages || 1;
      setProgress(((newPageInfo.position - 1) / totalPages) * 100);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [endpoint]);

  const handleAnswerChange = (questionId, value) => {
    setAnswers((prev) => ({ ...prev, [questionId]: value }));
  };

  const handleSubmit = async () => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    const unanswered = Object.values(answers).some((value) => value === null);

    if (unanswered) {
      alert(t('public_survey.questions.unansweredAlert'));
      return;
    }

    try {
      const response = await axios.post(`/public_surveys/${endpoint}/answers`, {
        uuid: surveySession.uuid,
        group_id: surveySession.group,
        responses: answers,
      });

      if (response.data.completed) {
        navigate('../complete');
      } else {
        fetchQuestions(response.data.next_page);
      }
    } catch (error) {
      console.error('Error submitting answers:', error);
    }
  };

  return (
    <Box p={6} mx="auto" maxW="lg">
      {/* Header with progress */}
      <Box mb={6} textAlign="center">
        <Text fontSize="2xl" fontWeight="bold">
          {pageInfo.title}
        </Text>
        <Progress value={progress} size="sm" colorScheme="blue" mt={2} />
      </Box>

      {/* Questions */}
      <VStack spacing={6}>
        {questions.map((q) => (
          <Card key={q.id} shadow="md" p={4} w="full" borderWidth="1px" borderRadius="md">
            <CardBody>
              <FormControl>
                <FormLabel>{q.question_text}</FormLabel>
                {q.question_type === 'number' ? (
                  <Box>
                    <Slider
                      min={1}
                      max={7}
                      step={1}
                      colorScheme="blue"
                      value={answers[q.id] || 4}
                      onChange={(val) => handleAnswerChange(q.id, val)}
                    >
                      <SliderMark value={1} mt="3" ml="-2.5" fontSize="sm">
                        {t('public_survey.questions.slider.disagree')}
                      </SliderMark>
                      <SliderMark value={4} mt="3" ml="-5" fontSize="sm">
                        {t('public_survey.questions.slider.neutral')}
                      </SliderMark>
                      <SliderMark value={7} mt="3" ml="-10" fontSize="sm">
                        {t('public_survey.questions.slider.agree')}
                      </SliderMark>
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb boxShadow="md" bgColor="#e70" />
                    </Slider>
                  </Box>
                ) : (
                  <RadioGroup
                    value={answers[q.id] || ''}
                    onChange={(val) => handleAnswerChange(q.id, parseInt(val, 10))}
                  >
                    <Stack direction="row">
                      {[1, 2, 3, 4, 5, 6, 7].map((num) => (
                        <Radio key={num} value={num.toString()}>
                          {num}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              </FormControl>
            </CardBody>
          </Card>
        ))}
      </VStack>

      {/* Submit Button */}
      <Box textAlign="center" mt={10}>
        {/* <Button onClick={handleSubmit} variant="outline" colorScheme="cyan" size="lg"> */}
        <Button
                colorScheme="teal"
                width="full"
                variant="outline"
                onClick={handleSubmit}
                _hover={{ bg: 'teal.600', color: "white" }}
                _focus={{ boxShadow: 'outline' }}
              >
          {t('public_survey.questions.next')}
        </Button>
      </Box>
    </Box>
  );
};

export default SurveyQuestions;