import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SurveyPageForm from './SurveyPageForm';
import { SurveyPagesProvider, useSurveyPages } from '../../contexts/SurveyPagesContext';
import { Box, Heading } from '@chakra-ui/react';

const SurveyPageCreateContent = () => {
  const { surveyId } = useParams();
  const { createPage } = useSurveyPages();
  const navigate = useNavigate();

  const handleCreatePage = async (pageData) => {
    await createPage(pageData);
    navigate(`/app/surveys/${surveyId}/pages`);
  };

  return (
    <Box>
      <Heading>Add page</Heading>
      <SurveyPageForm
        initialData={null} // No initial data for creating a new page
        onSubmit={handleCreatePage}
        onCancel={() => navigate(`/app/surveys/${surveyId}/pages`)}
      />
    </Box>
  );
};

const SurveyPageCreate = () => {
  const { surveyId } = useParams();

  return (
    <SurveyPagesProvider surveyId={surveyId}>
      <SurveyPageCreateContent />
    </SurveyPagesProvider>
  );
};

export default SurveyPageCreate;