import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import SurveyPageForm from './SurveyPageForm';
import { SurveyPagesProvider, useSurveyPages } from '../../contexts/SurveyPagesContext';

const SurveyPageEditContent = () => {
  const { surveyId, pageId } = useParams();
  const { updatePage } = useSurveyPages();
  const [initialData, setInitialData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`);
        setInitialData(response.data);
      } catch (error) {
        console.error('Error fetching page:', error);
      }
    };
    fetchPage();
  }, [surveyId, pageId]);

  const handleUpdatePage = async (pageData) => {
    await updatePage(pageId, pageData);
    navigate(`/app/surveys/${surveyId}/pages`);
  };

  return initialData ? (
    <SurveyPageForm
      initialData={initialData}
      onSubmit={handleUpdatePage}
      onCancel={() => navigate(`/app/surveys/${surveyId}/pages`)}
    />
  ) : (
    <div>Loading...</div>
  );
};

const SurveyPageEdit = () => {
  const { surveyId } = useParams();

  return (
    <SurveyPagesProvider surveyId={surveyId}>
      <SurveyPageEditContent />
    </SurveyPagesProvider>
  );
};

export default SurveyPageEdit;