import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading, Table, Thead, Tbody, Tr, Th, Td, Button, IconButton, Spacer } from '@chakra-ui/react';
import { FaPencilAlt, FaTrash, FaClipboardList } from 'react-icons/fa';
import axios from '../../axiosConfig';
import { Link } from 'react-router-dom';

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const response = await axios.get('/organizations');
        setOrganizations(response.data);
      } catch (error) {
        console.error('Error fetching organizations:', error);
      }
    };
    fetchOrganizations();
  }, []);

  const handleSoftDelete = async (id) => {
    try {
      await axios.patch(`/organizations/${id}`, { deleted: true });
      setOrganizations((prev) => prev.filter(org => org.id !== id));
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Organizations</Heading>
      <Flex minWidth='max-content' alignItems='center' gap='2'>
        <Spacer />
        <Button
          as={Link}
          to="/app/organizations/create"
          mt={4}
          colorScheme="green"
          variant="outline"
          size="sm"
        >
          Add Organization
        </Button>
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Contact Name</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {organizations.map((org) => (
            <Tr key={org.id}>
              <Td>{org.name}</Td>
              <Td>{org.contact}</Td>
              <Td>{org.email}</Td>
              <Td>{org.phone}</Td>
              <Td>
                {/* New Button to Manage Surveys */}
                <Link to={`/app/organizations/${org.id}/surveys`}>
                  <IconButton
                    icon={<FaClipboardList />}
                    aria-label="Manage Surveys"
                    title="Manage Surveys"
                    variant="outline"
                    size="sm"
                    colorScheme="cyan"
                    mr={2}
                  />
                </Link>
                <Link to={`/app/organizations/${org.id}/edit`}>
                  <IconButton
                    icon={<FaPencilAlt />}
                    aria-label="Edit"
                    variant="outline"
                    title="Edit Pages"
                    size="sm"
                    colorScheme="yellow"
                    mr={2}
                  />
                </Link>
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Delete"
                  title="Delete Page"
                  variant="outline"
                  colorScheme="red"
                  size="sm"
                  onClick={() => handleSoftDelete(org.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default OrganizationList;