import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const PublicSurveyGuard = ({ children }) => {
  const { endpoint } = useParams();
  const surveySession = JSON.parse(localStorage.getItem('survey_session'));

  // Check if session exists and matches the endpoint
  if (!surveySession || surveySession.endpoint !== endpoint) {
    return <Navigate to={`/survey/${endpoint}`} replace />;
  }

  return children;
};

export default PublicSurveyGuard;