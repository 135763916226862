import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import UserForm from './UserForm';

const UserCreate = () => {
  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>New User</Heading>
      <UserForm />
    </Box>
  );
};

export default UserCreate;