import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import QuestionsList from './QuestionsList';
import QuestionCreate from './QuestionCreate';
import QuestionEdit from './QuestionEdit';
import { QuestionsProvider } from '../../contexts/QuestionsContext';

const QuestionRoutes = () => {
  const { surveyId, pageId } = useParams();

  return (
    <QuestionsProvider surveyId={surveyId} surveyPageId={pageId}>
      <Routes>
        <Route path="" element={<QuestionsList />} />
        <Route path="create" element={<QuestionCreate />} />
        <Route path="edit/:questionId" element={<QuestionEdit />} />
      </Routes>
    </QuestionsProvider>
  );
};

export default QuestionRoutes;