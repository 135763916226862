import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OrganizationList from './OrganizationList';
import OrganizationSurveys from './OrganizationSurveys';
import SurveyConfigurationPage from './SurveyConfigurationPage';
import OrganizationForm from './OrganizationForm';

const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<OrganizationList />} />
      <Route path="create" element={<OrganizationForm />} />
      <Route path=":id/edit" element={<OrganizationForm />} />
      <Route path=":organizationId/surveys" element={<OrganizationSurveys />} />
      <Route
        path=":organizationId/surveys/:surveyId/configure"
        element={<SurveyConfigurationPage />}
      />
    </Routes>
  );
};

export default OrganizationRoutes;