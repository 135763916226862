import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../axiosConfig';
import QuestionForm from './QuestionForm';
import { useQuestions } from '../../contexts/QuestionsContext';

const QuestionCreate = () => {
  const { surveyId, pageId } = useParams();
  const { createQuestion } = useQuestions();
  const navigate = useNavigate();
  const [surveyDetails, setSurveyDetails] = useState({ surveyName: '', pageTitle: '' });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const [surveyResponse, pageResponse, groupsResponse] = await Promise.all([
          axios.get(`/surveys/${surveyId}`),
          axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`),
          axios.get(`/surveys/${surveyId}/survey_groups`),
        ]);

        setSurveyDetails({
          surveyName: surveyResponse.data.name,
          pageTitle: pageResponse.data.page.name,
        });

        setGroups(groupsResponse.data);
      } catch (error) {
        console.error('Error fetching survey or page details:', error);
      }
    };

    fetchDetails();
  }, [surveyId, pageId]);

  const handleCreateQuestion = async (questionData) => {
    await createQuestion(questionData);
    navigate(`/app/surveys/${surveyId}/pages/${pageId}/questions`);
  };

  return (
    <QuestionForm
      onSubmit={handleCreateQuestion}
      onCancel={() => navigate(`/app/surveys/${surveyId}/pages/${pageId}/questions`)}
      surveyId={surveyId}
      surveyDetails={surveyDetails}
      groups={groups}
    />
  );
};

export default QuestionCreate;