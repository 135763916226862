import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  IconButton,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  HStack,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SurveyLayout = ({ children }) => {
  const { endpoint } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [headerContent, setHeaderContent] = useState(t('public_survey.defaultHeader'));
  const [surveyName, setSurveyName] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        const response = await axios.get(`/public_surveys/${endpoint}`);
        if (response.data) {
          const { name, organization } = response.data;
          setSurveyName(name || '');
          setOrganizationName(organization || '');

          // Update localStorage
          const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
          localStorage.setItem(
            'survey_session',
            JSON.stringify({ ...surveySession, surveyName: name, organizationName: organization, endpoint })
          );
        }
      } catch (error) {
        console.error('Error fetching survey details:', error);
      }
    };

    fetchSurveyDetails();
  }, [endpoint]);

  useEffect(() => {
    // Dynamically update the header content based on the current route
    if (location.pathname.includes('/questions') || location.pathname.includes('/complete')) {
      setHeaderContent(`${organizationName} - ${surveyName}`);
    } else {
      setHeaderContent(surveyName || t('public_survey.defaultHeader'));
    }
  }, [location.pathname, organizationName, surveyName, t]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Flex direction="column" minH="100vh">
      {/* Header */}
      <Box
        as="header"
        bgGradient="linear-gradient(to right, rgba(162, 186, 6, 0.9), rgba(162, 186, 6, 0.9))"
        color="white"
        py={4}
        textAlign="center"
      >
        <Text fontSize={["lg", "2xl"]} fontWeight="bold" maxW="90%" mx="auto">
          {headerContent}
        </Text>
      </Box>

      {/* Main Content */}
      <VStack as="main" flex="1" p={6} mx="auto" maxW="lg" spacing={6}>
        {children}
      </VStack>

      {/* Footer */}
      <Box
        as="footer"
        bgGradient="linear-gradient(to right, rgba(229, 146, 59, 0.9), rgba(229, 146, 59, 0.9))"
        color="white"
        py={4}
        textAlign="center"
      >
        <HStack spacing={4} justifyContent="center">
          <Text fontSize="sm">{t('public_survey.footerText')}</Text>
          {/* Language Selector */}
          {/* Flags are being downloaded here: https://hatscripts.github.io/circle-flags/gallery */}
          <Popover placement="top">
            <PopoverTrigger>
              <IconButton
                aria-label="Select Language"
                icon={
                  <Image
                    src={`/images/country-flags/${i18n.language === 'en' ? 'gb.svg' : 'se.svg'}`}
                    alt={i18n.language === 'en' ? 'English' : 'Swedish'}
                    boxSize="26px"
                    borderRadius="full"
                    boxShadow="md"
                  />
                }
                size="lg"
                bg="transparent"
                borderRadius="full"
                _hover={{ bg: 'gray.100' }}
              />
            </PopoverTrigger>
            <PopoverContent w="auto" p="2">
              <PopoverBody>
                <Button
                  variant="ghost"
                  w="full"
                  justifyContent="flex-start"
                  onClick={() => changeLanguage('en')}
                  leftIcon={
                    <Image
                      src="/images/country-flags/gb.svg"
                      alt="English"
                      boxSize="20px"
                      borderRadius="full"
                    />
                  }
                >
                  English
                </Button>
                <Button
                  variant="ghost"
                  w="full"
                  justifyContent="flex-start"
                  onClick={() => changeLanguage('sv')}
                  leftIcon={
                    <Image
                      src="/images/country-flags/se.svg"
                      alt="Swedish"
                      boxSize="20px"
                      borderRadius="full"
                    />
                  }
                >
                  Swedish
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </HStack>
      </Box>
    </Flex>
  );
};

export default SurveyLayout;