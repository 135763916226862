import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserList from './UserList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

const UserRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<UserList />} />
      <Route path="create" element={<UserCreate />} />
      <Route path="edit/:id" element={<UserEdit />} />
    </Routes>
  );
};

export default UserRoutes;