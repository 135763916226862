import React, { useState, useEffect } from 'react';
import {
  Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  Button, Checkbox, Stack, Text, useDisclosure
} from '@chakra-ui/react';
import Cookies from 'js-cookie';

const CookieConsentDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      onOpen();  // Automatically open the drawer if there's no cookie consent
    }
  }, [onOpen]);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'true', { expires: 365 });
    Cookies.set('functionalCookies', 'true', { expires: 365 });  // Set functional cookies

    if (analyticsEnabled) {
      Cookies.set('analyticsCookies', 'true', { expires: 365 });
      // Initialize analytics tracking
    } else {
      Cookies.remove('analyticsCookies');
      // Disable analytics tracking
    }
    onClose();  // Close the drawer
  };

  const handleAnalyticsToggle = (event) => {
    setAnalyticsEnabled(event.target.checked);
  };

  return (
    <>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Cookie Preferences</DrawerHeader>
          <DrawerBody>
            <Text>We use cookies to enhance your experience. Please select your preferences below:</Text>
            <Stack mt={4} spacing={4}>
              <Checkbox isChecked={true} isDisabled>
                <strong>Functional Cookies (Required):</strong> These cookies are necessary for the proper functioning of our platform, such as logging in and accessing secure areas.
              </Checkbox>
              <Checkbox isChecked={analyticsEnabled} onChange={handleAnalyticsToggle}>
                <strong>Analytics Cookies (Optional):</strong> These cookies help us understand how visitors interact with our website, allowing us to improve user experience.
              </Checkbox>
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button colorScheme="blue" onClick={handleAccept}>Accept and Continue</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CookieConsentDrawer;