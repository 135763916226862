import React, { useEffect } from 'react';
import { ChakraProvider, useColorMode } from '@chakra-ui/react';
import Header from './Header';
import Footer from './Footer';
import MainContent from './MainContent';
import '../styles/custom.css';

const Website = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    // Force light mode when the Website component is mounted
    setColorMode('light');
  }, [setColorMode]);

  return (
    <ChakraProvider>
      <Header />
      <MainContent />
      <Footer />
    </ChakraProvider>
  );
};

export default Website;