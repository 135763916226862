import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  VStack,
} from '@chakra-ui/react';

const SurveyPageForm = ({ initialData, onSubmit, onCancel }) => {
  const [name, setName] = useState(initialData?.name || '');
  const [position, setPosition] = useState(initialData?.position || '');

  useEffect(() => {
    setName(initialData?.name || '');
    setPosition(initialData?.position || '');
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, position });
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Page Name</FormLabel>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter page name"
            />
          </FormControl>

          <FormControl>
            <FormLabel>Position</FormLabel>
            <NumberInput min={1} value={position} onChange={(value) => setPosition(value)}>
              <NumberInputField />
            </NumberInput>
          </FormControl>

          <Button type="submit" colorScheme="green">
            {initialData ? 'Update Page' : 'Create Page'}
          </Button>
          <Button variant="ghost" onClick={onCancel}>
            Cancel
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default SurveyPageForm;