import React, { useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Link,
  useDisclosure,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const TermsAndPrivacyDrawer = ({ type, update }) => {
  const { i18n, t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const loadContent = async () => {
    setLoading(true);
    let filePath = '';
    if (type === 'terms') {
      filePath = `/terms-${i18n.language}.html`; // Dynamically select file based on language
    } else if (type === 'privacy') {
      filePath = `/privacy-${i18n.language}.html`;
    } else if (type === 'gdpr') {
      filePath = `/gdpr-${i18n.language}.html`;
    }

    try {
      const response = await fetch(filePath);
      const data = await response.text();
      setContent(data);
    } catch (error) {
      setContent(`<p>${t('website.footer.drawerContent.error')}</p>`);
    }
    setLoading(false);
    onOpen();
  };

  return (
    <>
      <Link
        fontSize="sm"
        onClick={loadContent}
        style={{ marginRight: '1em', cursor: 'pointer' }}
      >
        {type === 'terms'
          ? t('website.footer.drawerContent.terms')
          : type === 'privacy'
          ? t('website.footer.drawerContent.privacy')
          : t('website.footer.drawerContent.gdpr')}
      </Link>
      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {type === 'terms'
              ? t('website.footer.drawerContent.terms')
              : type === 'privacy'
              ? t('website.footer.drawerContent.privacy')
              : t('website.footer.drawerContent.gdpr')}
          </DrawerHeader>
          <DrawerBody>
            {loading ? <Spinner /> : <Text dangerouslySetInnerHTML={{ __html: content }} />}
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Text fontSize="sm" as="i">
              {t('website.footer.drawerContent.lastUpdated')} {update}
            </Text>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TermsAndPrivacyDrawer;