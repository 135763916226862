import {
  Box,
  Image,
  Heading,
  Text,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';
import companyLogo from '../images/hke-logo-lg.png';
import companyMiniLogo from '../images/hke-logo-sm.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const handleScroll = () => {
      const stickyNav = document.getElementById('sticky-navbar');
      const headerHeight = document.getElementById('header').offsetHeight;

      if (window.scrollY > headerHeight) {
        stickyNav.style.visibility = 'visible';
        stickyNav.style.opacity = 1;
        stickyNav.style.transform = 'translateY(0)';
      } else {
        stickyNav.style.visibility = 'hidden';
        stickyNav.style.opacity = 0;
        stickyNav.style.transform = 'translateY(-100%)';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* Main Header Section */}
      <Box
        id="header"
        bgGradient="linear(to-tr, #A2BA06, #4EB9C5, #E5923B)"
        color="white"
        textAlign="center"
        py={{ base: '4rem', md: '8rem' }}
      >
        <Image src={companyLogo} alt="HKE Learning Logo" maxW="600px" width="100%" mx="auto" />
        <Heading as="h1" mt={4} fontSize={{ base: '2xl', md: '4xl' }} color="white">
          {t('website.header.title')}
        </Heading>
        <Text mt={4} fontSize={{ base: 'md', md: 'xl' }} maxW="700px" mx="auto" color="white">
          {t('website.header.description')}
        </Text>
        <Text mt={2} fontSize={{ base: 'md', md: 'xl' }} fontStyle="italic" maxW="700px" mx="auto" color="white">
          {t('website.header.subtitle')}
        </Text>
        <Flex justifyContent="center" mt={20}>
          <Button as="a" href="#cta-one" colorScheme="gray" size="lg" className="scrolly" variant="outline">
            {t('website.header.discoverButton')}
          </Button>
        </Flex>
      </Box>

      {/* Sticky Navbar */}
      <Flex
        id="sticky-navbar"
        position="fixed"
        top="0"
        left="0"
        right="0"
        bg="white"
        boxShadow="lg"
        zIndex="1000"
        justify="space-between"
        align="center"
        px={4}
        py={3}
        visibility="hidden"
        opacity={0}
        transform="translateY(-100%)"
        transition="all 0.3s"
      >
        <Image src={companyMiniLogo} alt="Logo" h="50px" />
        <IconButton
          aria-label="Open Menu"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          display={{ base: 'block', md: 'none' }}
        />
        <Flex as="ul" listStyleType="none" gap={8} display={{ base: 'none', md: 'flex' }}>
          <Button variant="link" onClick={() => document.getElementById('header').scrollIntoView()}>
            {t('website.navbar.home')}
          </Button>
          <Button variant="link" onClick={() => document.getElementById('about').scrollIntoView()}>
            {t('website.navbar.aboutUs')}
          </Button>
          <Button variant="link" onClick={() => document.getElementById('team').scrollIntoView()}>
            {t('website.navbar.ourTeam')}
          </Button>
          <Button variant="link" onClick={() => document.getElementById('services').scrollIntoView()}>
            {t('website.navbar.services')}
          </Button>
          <Button variant="link" onClick={() => document.getElementById('contact').scrollIntoView()}>
            {t('website.navbar.contact')}
          </Button>
        </Flex>

        {/* Language Selector */}
        {/* <Flex display={{ base: 'none', md: 'flex' }}>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              colorScheme="gray"
              variant="outline"
              size="sm"
            >
              {i18n.language === 'en' ? 'English' : 'Svenska'}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
              <MenuItem onClick={() => changeLanguage('sv')}>Svenska</MenuItem>
            </MenuList>
          </Menu>
        </Flex> */}
      </Flex>

      {/* Drawer for mobile menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Flex as="ul" direction="column" listStyleType="none" gap={4}>
              <Button variant="link" onClick={() => { onClose(); document.getElementById('header').scrollIntoView(); }}>
                {t('website.navbar.home')}
              </Button>
              <Button variant="link" onClick={() => { onClose(); document.getElementById('about').scrollIntoView(); }}>
                {t('website.navbar.aboutUs')}
              </Button>
              <Button variant="link" onClick={() => { onClose(); document.getElementById('team').scrollIntoView(); }}>
                {t('website.navbar.ourTeam')}
              </Button>
              <Button variant="link" onClick={() => { onClose(); document.getElementById('services').scrollIntoView(); }}>
                {t('website.navbar.services')}
              </Button>
              <Button variant="link" onClick={() => { onClose(); document.getElementById('contact').scrollIntoView(); }}>
                {t('website.navbar.contact')}
              </Button>
              {/* <Button
                variant="link"
                onClick={() => { onClose(); changeLanguage('en'); }}
                isActive={i18n.language === 'en'}
              >
                English
              </Button>
              <Button
                variant="link"
                onClick={() => { onClose(); changeLanguage('sv'); }}
                isActive={i18n.language === 'sv'}
              >
                Svenska
              </Button> */}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Header;