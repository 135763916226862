import { Box, Flex, Link, Icon, Text, Stack, HStack } from '@chakra-ui/react';
import { FaFacebookF, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import TermsAndPrivacyDrawer from './TermsAndPrivacyDrawer';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box
      as="section"
      id="footer"
      py="6em"
      textAlign="center"
      color="rgba(255, 255, 255, 0.75)"
      bgImage="linear-gradient(to-tr, #4361c2, #4fa49a 50%, #9dc66b 95%)"
      bgBlendMode="overlay"
      bgPosition="top left, center center, center center"
      bgAttachment="fixed, fixed, fixed"
      bgSize="auto, cover, cover"
    >
      {/* Social Icons */}
      <HStack justify="center" spacing={6} mb={6}>
        <Link href="https://www.facebook.com/profile.php?id=61567834184033" isExternal aria-label={t('website.footer.socialLinks.facebook')}>
          <Icon as={FaFacebookF} boxSize={6} color="white" />
        </Link>
        <Link href="https://linkedin.com/company/hke-learning" isExternal aria-label={t('website.footer.socialLinks.linkedin')}>
          <Icon as={FaLinkedin} boxSize={6} color="white" />
        </Link>
        <Link href="mailto:info@hke.global" isExternal aria-label={t('website.footer.socialLinks.email')}>
          <Icon as={FaEnvelope} boxSize={6} color="white" />
        </Link>
      </HStack>

      {/* Privacy Policy and Terms of Use */}
      <Stack
        justify="center"
        spacing={4}
        mt={4}
        mb={6}
        direction={{ base: 'column', md: 'row' }}
        align="center"
      >
        <TermsAndPrivacyDrawer type="terms" title={t('website.footer.termsOfUse')} update='07/Oct/2024' />
        <TermsAndPrivacyDrawer type="privacy" title={t('website.footer.privacyPolicy')} update='09/Oct/2024' />
        <TermsAndPrivacyDrawer type="gdpr" title={t('website.footer.gdpr')} update='11/Oct/2024' />
      </Stack>

      {/* Copyright Text */}
      <Flex justify="center" mt={4}>
        <Text fontSize="0.8em" color="rgba(255, 255, 255, 0.75)">
          <Link href="/login">
            {t('website.footer.copyright')}
          </Link>
        </Text>
      </Flex>
    </Box>
  );
};

export default Footer;