import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';

const SurveyPagesContext = createContext();

export const useSurveyPages = () => {
  console.log("Accessing useSurveyPages");  // Debugging line
  return useContext(SurveyPagesContext);
};

export const SurveyPagesProvider = ({ surveyId, children }) => {
  const [pages, setPages] = useState([]);

  const fetchPages = async () => {
    try {
      const response = await axios.get(`/surveys/${surveyId}/survey_pages`);
      setPages(response.data);
    } catch (error) {
      console.error("Error fetching survey pages:", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, [surveyId]); // Fetches pages when surveyId changes

  const createPage = async (pageData) => {
    try {
      const response = await axios.post(`/surveys/${surveyId}/survey_pages`, pageData);
      setPages([...pages, response.data]);
    } catch (error) {
      console.error("Error creating survey page:", error);
    }
  };

  const updatePage = async (pageId, pageData) => {
    try {
      const response = await axios.put(`/surveys/${surveyId}/survey_pages/${pageId}`, pageData);
      setPages(pages.map(page => (page.id === pageId ? response.data : page)));
    } catch (error) {
      console.error("Error updating survey page:", error);
    }
  };

  const deletePage = async (pageId) => {
    try {
      await axios.delete(`/surveys/${surveyId}/survey_pages/${pageId}`);
      setPages(pages.filter(page => page.id !== pageId));
    } catch (error) {
      console.error("Error deleting survey page:", error);
    }
  };

  console.log("SurveyPagesProvider is rendering");  // Debugging line

  return (
    <SurveyPagesContext.Provider value={{ pages, fetchPages, createPage, updatePage, deletePage }}>
      {children}
    </SurveyPagesContext.Provider>
  );
};