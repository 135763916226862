import { extendTheme } from '@chakra-ui/react';

// Custom theme configuration with color modes
const customTheme = extendTheme({
  config: {
    initialColorMode: 'light', // Set the default to dark mode
    useSystemColorMode: false, // Disable using system color mode
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? '#1a202c' : '#f0f0f0', // Dark/Light background
        color: props.colorMode === 'dark' ? '#f0f0f0' : '#333', // Text color for dark/light
        fontFamily: 'Source Sans Pro, sans-serif',
        transition: 'background-color 0.2s ease-in-out',
      },
    }),
  },
  fonts: {
    body: 'Source Sans Pro, sans-serif',
    heading: 'Source Sans Pro, sans-serif',
  },
  colors: {
    brand: {
      primary: '#4EB9C5',
      secondary: '#A2BA06',
      accent: '#E5923B',
    },
  },
});

export default customTheme;