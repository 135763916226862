import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../axiosConfig';
import Cookies from 'js-cookie';
import { useColorMode } from '@chakra-ui/react';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [settings, setSettings] = useState({ theme: 'light', language: 'English' });
  const colorMode = useColorMode();
  
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = Cookies.get('hkeAuthToken');
        if (!token) return;
        const response = await axios.get('/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
        if (response.data.settings) {
          setSettings(response.data.settings);
        }
      } catch (error) {
        console.log('Error fetching user data:', error);
      }
    };

    if (!userData) {
      fetchUserDetails();
    }
  }, [userData]);

  // Apply theme mode immediately whenever settings.theme changes
  useEffect(() => {
    if (colorMode.setColorMode) {
      colorMode.setColorMode(settings.theme || 'light');
    } else {
      console.warn("setColorMode is undefined, verify Chakra setup.");
    }
  }, [settings.theme, colorMode]);

  const updateUser = async (updatedUser) => {
    try {
      const token = Cookies.get('hkeAuthToken');
      await axios.patch(`/users/me/profile`, updatedUser, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const updateSettings = async (newSettings) => {
    try {
      const token = Cookies.get('hkeAuthToken');
      await axios.patch(`/users/me/settings`, newSettings, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSettings(newSettings);
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await axios.patch('/users/me/change_password', {
        current_password: currentPassword,
        password: newPassword,
      });
      return response.status === 200;
    } catch (error) {
      console.error('Failed to change password:', error);
      throw error;
    }
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, settings, setSettings, updateUser, updateSettings, changePassword }}>
      {children}
    </UserContext.Provider>
  );
};