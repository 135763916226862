import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import QuestionForm from './QuestionForm';
import { useQuestions } from '../../contexts/QuestionsContext';

const QuestionEdit = () => {
  const { surveyId, pageId, questionId } = useParams();
  const { updateQuestion, fetchQuestions } = useQuestions();
  const [initialData, setInitialData] = useState(null);
  const [surveyDetails, setSurveyDetails] = useState({ surveyName: '', pageTitle: '' });
  const [groups, setGroups] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [questionResponse, surveyResponse, pageResponse, groupsResponse] = await Promise.all([
          axios.get(`/surveys/${surveyId}/survey_pages/${pageId}/questions/${questionId}`),
          axios.get(`/surveys/${surveyId}`),
          axios.get(`/surveys/${surveyId}/survey_pages/${pageId}`),
          axios.get(`/surveys/${surveyId}/survey_groups`),
        ]);

        setInitialData(questionResponse.data);
        setSurveyDetails({
          surveyName: surveyResponse.data.name,
          pageTitle: pageResponse.data.page.name,
        });
        setGroups(groupsResponse.data);
      } catch (error) {
        console.error('Error fetching question or survey details:', error);
      }
    };

    fetchData();
  }, [surveyId, pageId, questionId]);

  const handleUpdateQuestion = async (questionData) => {
    try {
      await updateQuestion(questionId, questionData);
      await fetchQuestions(); // Refresh the questions list
      navigate(`/app/surveys/${surveyId}/pages/${pageId}/questions`);
    } catch (error) {
      console.error('Error updating question:', error);
    }
  };

  return initialData ? (
    <QuestionForm
      initialData={initialData}
      onSubmit={handleUpdateQuestion}
      onCancel={() => navigate(`/app/surveys/${surveyId}/pages/${pageId}/questions`)}
      surveyId={surveyId}
      surveyDetails={surveyDetails}
      groups={groups}
    />
  ) : (
    <div>Loading...</div>
  );
};

export default QuestionEdit;