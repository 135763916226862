import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormControl,
  FormLabel,
  Heading,
  Select,
  Text,
  VStack,
  useToast,
  Divider,
} from '@chakra-ui/react';
import axios from '../../axiosConfig';
import { useNavigate, useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const SurveyAcknowledgment = () => {
  const { endpoint } = useParams();
  const { t } = useTranslation();
  const [openingMessage, setOpeningMessage] = useState('');
  const [group, setGroup] = useState('');
  const [role, setRole] = useState('');
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const surveySession = JSON.parse(localStorage.getItem('survey_session'));
    if (surveySession?.group && surveySession.role) {
      navigate('../questions');
    }
  }, [endpoint, navigate]);

  useEffect(() => {
    // Load previously selected group and role from localStorage
    const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
    if (surveySession.group) setGroup(surveySession.group);
    if (surveySession.role) setRole(surveySession.role);

    const fetchDetails = async () => {
      try {
        const response = await axios.get(`/public_surveys/${endpoint}/acknowledgment`);
        const { opening_message, groups, roles } = response.data;

        setOpeningMessage(opening_message || t('public_survey.acknowledgment.defaultMessage'));
        setGroups(groups || []);
        setRoles(roles || []);
      } catch (error) {
        console.error('Error fetching survey acknowledgment details:', error);
      }
    };

    fetchDetails();
  }, [endpoint, t]);

  const handleNext = async () => {
    try {
      const response = await axios.post(`/public_surveys/${endpoint}/respondent`, {
        group_id: group,
        role,
      });

      const { uuid } = response.data;

      // Save respondent details in localStorage
      const surveySession = JSON.parse(localStorage.getItem('survey_session')) || {};
      localStorage.setItem(
        'survey_session',
        JSON.stringify({ ...surveySession, group, role, uuid })
      );

      // Navigate to questions page
      navigate('../questions');
    } catch (error) {
      console.error('Error storing data:', error);
      toast({
        title: t('public_survey.acknowledgment.error.title'),
        description: t('public_survey.acknowledgment.error.description'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && group && role) {
      handleNext();
    }
  };

  return (
    <Box p={6} mx="auto" maxW="lg">
      <VStack spacing={6}>
        {/* Hero Section */}
        <Box
          textAlign="center"
          p={6}
          bgGradient="linear(to-r, cyan.500, teal.500, teal.500, cyan.500)"
          borderRadius="md"
          color="white"
          boxShadow="lg"
        >
          <Heading size="lg" mb={2}>
            {t('public_survey.acknowledgment.welcome')}
          </Heading>
          <Text
            fontSize="md"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(openingMessage) }}
          />
        </Box>

        <Divider />

        {/* Group and Role Selection */}
        <Card shadow="md" border="1px" borderColor="gray.200">
          <CardHeader>
            <Heading size="md" textAlign="center" color="gray.700">
              {t('public_survey.acknowledgment.selectDetails')}
            </Heading>
          </CardHeader>
          <CardBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>{t('public_survey.acknowledgment.selectGroup')}</FormLabel>
                <Select
                  placeholder={t('public_survey.acknowledgment.groupPlaceholder')}
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  onKeyDown={handleKeyDown}
                >
                  {groups.map((g) => (
                    <option key={g[0]} value={g[0]}>
                      {g[1]}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>{t('public_survey.acknowledgment.selectRole')}</FormLabel>
                <Select
                  placeholder={t('public_survey.acknowledgment.rolePlaceholder')}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  onKeyDown={handleKeyDown}
                >
                  {roles.map((r, index) => (
                    <option key={index} value={r}>
                      {r}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </VStack>
          </CardBody>
          <CardFooter>
            <Button
              onClick={handleNext}
              colorScheme="teal"
              variant="outline"
              width="full"
              isDisabled={!group || !role}
              _hover={{ bg: 'teal.600', color: "white" }}
            >
              {t('public_survey.acknowledgment.proceed')}
            </Button>
          </CardFooter>
        </Card>
      </VStack>
    </Box>
  );
};

export default SurveyAcknowledgment;