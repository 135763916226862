import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Select, VStack, Heading, useToast } from '@chakra-ui/react';
import { useUser } from '../../contexts/UserContext';

const Settings = () => {
  const { settings, updateSettings } = useUser();
  const [theme, setTheme] = useState(settings.theme);
  const [language, setLanguage] = useState(settings.language);
  const toast = useToast();

  useEffect(() => {
    setTheme(settings.theme);
    setLanguage(settings.language);
  }, [settings]);

  const handleSave = () => {
    updateSettings({ theme, language });
    toast({
      title: 'Settings updated.',
      description: 'Your preferences have been saved.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Box maxW="lg" mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={6}>Settings</Heading>
      <VStack spacing={4} align="stretch">
        {/* Theme Selection */}
        <FormControl>
          <FormLabel>Theme</FormLabel>
          <Select value={theme} onChange={(e) => setTheme(e.target.value)}>
            <option value="light">Light</option>
            <option value="dark">Dark</option>
          </Select>
        </FormControl>

        {/* Language Selection */}
        <FormControl>
          <FormLabel>Language</FormLabel>
          <Select value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="english">English</option>
            <option value="swedish">Swedish</option>
          </Select>
        </FormControl>

        {/* Save Button */}
        <Button onClick={handleSave} colorScheme="green" size="lg" width="full">
          Save Changes
        </Button>
      </VStack>
    </Box>
  );
};

export default Settings;