import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Flex,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useParams, Link } from 'react-router-dom';
import { FaTrash, FaPencilAlt, FaClipboardCheck } from 'react-icons/fa';
import axios from '../../axiosConfig';
import { useSurveyPages, SurveyPagesProvider } from '../../contexts/SurveyPagesContext';

const SurveyPageListContent = () => {
  const { surveyId } = useParams();
  const { pages, fetchPages, deletePage } = useSurveyPages();
  const [survey, setSurvey] = useState(null);

  // Fetch survey details for header
  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`/surveys/${surveyId}`);
        setSurvey(response.data);
      } catch (error) {
        console.error("Error fetching survey:", error);
      }
    };

    fetchSurvey();
  }, [surveyId]);

  // Fetch pages initially
  useEffect(() => {
    fetchPages();
  }, [surveyId]);

  return (
    <Box mx="auto" p={6}>
      <Heading as="h2" size="lg" mb={4}>{survey ? survey.name : 'Survey'}</Heading>
      <Text fontSize="lg" color="gray.500">Manage Pages</Text>
      <Flex mt={4} mb={6}>
        <Spacer />
        <Button colorScheme="green" variant="outline" size="sm" as={Link} to={`/app/surveys/${surveyId}/pages/create`}>
          Add Page
        </Button>
      </Flex>

      {pages.length > 0 ? (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Page Name</Th>
                <Th>Position</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pages.map((page) => (
                <Tr key={page.id}>
                  <Td>{page.name}</Td>
                  <Td>{page.position}</Td>
                  <Td>
                    <IconButton
                      icon={<FaClipboardCheck />}
                      colorScheme="cyan"
                      title="Manage Questions"
                      variant="outline"
                      as={Link}
                      size="sm"
                      to={`/app/surveys/${surveyId}/pages/${page.id}/questions`}
                      mr={2}
                    />
                    <IconButton
                      icon={<FaPencilAlt />}
                      colorScheme="yellow"
                      title="Edit Page"
                      variant="outline"
                      as={Link}
                      size="sm"
                      to={`/app/surveys/${surveyId}/pages/edit/${page.id}`}
                      mr={2}
                    />
                    <IconButton
                      icon={<FaTrash />}
                      title="Delete Page"
                      variant="outline"
                      colorScheme="red"
                      size="sm"
                      onClick={() => deletePage(page.id)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Text mt={6} textAlign="center">
          No pages found for this survey.
        </Text>
      )}
    </Box>
  );
};

// Wrapping SurveyPageListContent in SurveyPagesProvider to access survey pages
const SurveyPageList = () => {
  const { surveyId } = useParams();

  return (
    <SurveyPagesProvider surveyId={surveyId}>
      <SurveyPageListContent />
    </SurveyPagesProvider>
  );
};

export default SurveyPageList;