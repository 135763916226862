import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  List,
  UnorderedList,
  ListItem,
  Grid,
  GridItem,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useToast,
  VisuallyHidden,
} from '@chakra-ui/react';
import aboutUsImage from '../images/holding-cogs.webp';
import axios from '../../axiosConfig';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';

const MainContent = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState({ name: '', email: '', phone: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const messagePayload = {
      message: {
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone,
        content: formValues.message,
      },
    };

    try {
      await axios.post('/messages', messagePayload);
      toast({
        title: t('website.mainContent.contact.form.success'),
        description: t('website.mainContent.contact.form.successMessage'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setFormValues({ name: '', email: '', phone: '', message: '' }); // Reset form
    } catch (error) {
      toast({
        title: t('website.mainContent.contact.form.error'),
        description: t('website.mainContent.contact.form.errorMessage'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {/* Call to Action */}
      <Box
        id="cta-one"
        bgGradient="linear(to-r, rgba(162, 186, 6, 0.9), rgba(162, 186, 6, 0.9))"
        bgBlendMode="overlay"
        bgAttachment="fixed"
        backgroundSize="cover"
        py={40}
        textAlign="center"
        color="white"
      >
        <Container maxW="container.md" fontSize="xl">
          <Heading as="h2">{t('website.mainContent.cta.heading')}</Heading>
          <Text mt={4}>{t('website.mainContent.cta.text')}</Text>
          <Text mt={4}>{t('website.mainContent.cta.exploreServices')}</Text>
          <Button as="a" href="#services" colorScheme="gray" variant="outline" className="scrolly" mt={12} size="lg">
            {t('website.mainContent.cta.button')}
          </Button>
        </Container>
      </Box>

      {/* About Section */}
      <Box id="about" py={20} bg="white">
        <Container maxW="container.xl">
          <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={8}>
            <GridItem fontSize="xl">
              <Heading as="h2">{t('website.mainContent.about.heading')}</Heading>
              <Text mt={8}>{t('website.mainContent.about.mission')}</Text>
              <Text mt={8}>{t('website.mainContent.about.journey')}</Text>
              <Text mt={8}>{t('website.mainContent.about.excellence')}</Text>
            </GridItem>
            <GridItem>
              <Image src={aboutUsImage} alt={t('website.mainContent.about.altText')} fit="cover" />
            </GridItem>
          </Grid>
        </Container>
      </Box>

      {/* Our Values */}
      <Box
        id="values"
        bgImage="linear-gradient(rgba(78, 185, 197, 0.9), rgba(78, 185, 197, 0.9))"
        bgBlendMode="overlay"
        bgAttachment="fixed"
        bgSize="cover"
        color="#333"
        py={16}
      >
        <Container maxW="container.lg">
          <Heading as="h2" size="xl" mb={6} textAlign="center">
            {t('website.mainContent.values.heading')}
          </Heading>
          <List spacing={4} fontSize="xl">
            <ListItem>{t('website.mainContent.values.systemCoherence')}</ListItem>
            <ListItem>{t('website.mainContent.values.innovation')}</ListItem>
            <ListItem>{t('website.mainContent.values.collaboration')}</ListItem>
            <ListItem>{t('website.mainContent.values.integrity')}</ListItem>
            <ListItem>{t('website.mainContent.values.empowerment')}</ListItem>
          </List>
        </Container>
      </Box>

      {/* Team Section */}
      <Box id="team" py={20} bg="white">
        <Container maxW="container.lg" fontSize="xl">
          <Heading as="h2" textAlign="center">
            {t('website.mainContent.team.heading')}
          </Heading>
          <Text textAlign="center" mt={4}>{t('website.mainContent.team.description')}</Text>
          <Grid templateColumns={{ base: '1fr', md: 'repeat(4, 1fr)' }} gap={8} mt={8}>
            <GridItem>
              <Image src="images/peter.webp" alt={t('website.mainContent.team.peter.name')} fit="cover" />
              <Heading as="h3" size="md" mt={4}>
                {t('website.mainContent.team.peter.name')} – {t('website.mainContent.team.peter.title')}
              </Heading>
              <Text mt={2}>{t('website.mainContent.team.peter.bio')}</Text>
            </GridItem>
            <GridItem>
              <Image src="images/ulf.webp" alt={t('website.mainContent.team.ulf.name')} fit="cover" />
              <Heading as="h3" size="md" mt={4}>
                {t('website.mainContent.team.ulf.name')} – {t('website.mainContent.team.ulf.title')}
              </Heading>
              <Text mt={2}>{t('website.mainContent.team.ulf.bio')}</Text>
            </GridItem>
            <GridItem>
              <Image src="images/chris.webp" alt={t('website.mainContent.team.christoffer.name')} fit="cover" />
              <Heading as="h3" size="md" mt={4}>
                {t('website.mainContent.team.christoffer.name')} – {t('website.mainContent.team.christoffer.title')}
              </Heading>
              <Text mt={2}>{t('website.mainContent.team.christoffer.bio')}</Text>
            </GridItem>
            <GridItem>
              <Image src="images/cl.webp" alt={t('website.mainContent.team.cleyton.name')} fit="cover" />
              <Heading as="h3" size="md" mt={4}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${t('website.mainContent.team.cleyton.name')} – ${t('website.mainContent.team.cleyton.title')}`
                  }}
                />
              </Heading>
              <Text mt={2}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('website.mainContent.team.cleyton.bio')
                  }}
                />
              </Text>
            </GridItem>
          </Grid>
        </Container>
      </Box>

      {/* Services Section */}
      <Box
        id="services"
        bgGradient="linear(to-r, rgba(229, 146, 59, 0.9), rgba(229, 146, 59, 0.9))"
        bgBlendMode="overlay"
        bgAttachment="fixed"
        backgroundSize="cover"
        py={20}
        color="#333"
      >
        <Container maxW="container.lg" fontSize="xl">
          <Heading as="h2" textAlign="center">{t('website.mainContent.services.heading')}</Heading>
          <Text mt={4}>{t('website.mainContent.services.description')}</Text>
          <UnorderedList mt={8} spacing={4}>
            <ListItem>{t('website.mainContent.services.items.surveyPlatform')}</ListItem>
            <ListItem>{t('website.mainContent.services.items.hub')}</ListItem>
            <ListItem>{t('website.mainContent.services.items.webinars')}</ListItem>
          </UnorderedList>
          <Heading as="h3" size="lg" mt={8}>{t('website.mainContent.services.benefits.heading')}</Heading>
          <UnorderedList mt={4} spacing={4}>
            <ListItem>{t('website.mainContent.services.benefits.easyToUse')}</ListItem>
            <ListItem>{t('website.mainContent.services.benefits.customizable')}</ListItem>
            <ListItem>{t('website.mainContent.services.benefits.scalable')}</ListItem>
          </UnorderedList>
        </Container>
      </Box>

      {/* Contact Section */}
      <Box id="contact" py={20} bg="white">
        <Container fontSize="xl">
          <Heading as="h2" textAlign="center">{t('website.mainContent.contact.heading')}</Heading>
          <form id="contact-form" onSubmit={handleSubmit}>
            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={6} mt={8}>
              <GridItem>
                <VisuallyHidden>
                  <input type="text" name="phone" value={formValues.phone} onChange={handleInputChange} />
                </VisuallyHidden>
                <FormControl>
                  <FormLabel>{t('website.mainContent.contact.form.name')}</FormLabel>
                  <Input type="text" name="name" value={formValues.name} onChange={handleInputChange} required />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl>
                  <FormLabel>{t('website.mainContent.contact.form.email')}</FormLabel>
                  <Input type="email" name="email" value={formValues.email} onChange={handleInputChange} required />
                </FormControl>
              </GridItem>
            </Grid>
            <FormControl mt={6}>
              <FormLabel>{t('website.mainContent.contact.form.message')}</FormLabel>
              <Textarea name="message" rows={6} value={formValues.message} onChange={handleInputChange} required />
            </FormControl>
            <Box textAlign="center">
              <Button type="submit" mt={6} colorScheme="orange" size="lg" variant="outline">
                {isSubmitting ? t('website.mainContent.contact.form.sending') : t('website.mainContent.contact.form.submitButton')}
              </Button>
            </Box>
          </form>
          <Text mt={6} textAlign="center">
            {t('website.mainContent.contact.altText')}
          </Text>
          <Text fontSize="3xl" mt={6} textAlign="center">{t('website.mainContent.contact.statement')}</Text>
        </Container>
      </Box>
    </>
  );
};

export default MainContent;